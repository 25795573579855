.item-box {
    background-color: white;
    border-style: solid;
    border-radius: 5px;
    border-color: #777;
    border-width: 1px;
    box-shadow: 2px 2px black;
    padding: 15px 23px;
    margin: 12px 2px;
}

/* Optional class */
.item-box-hover-effect:hover {
    background-color: #eafaff;
    border-color: black;
    cursor: pointer;
}