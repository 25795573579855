/* Logo */
.default-navbar-logo {
    font-family: 'Bebas Neue', 'Squada One', Helvetica, sans-serif;
    font-size: 52px;
    font-weight: 500;
    color: white;
    text-shadow: #698 1px 1px 1px;
    margin: 5px 0 0 0;
    display: inline-block;
}
