/* Article */

.article__author-date-box {
    margin: 7px 0px;
    padding: 3px 6px;
    display: inline-block;

    background-color: #aaffdd;

    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
}

.article__author {
    color: #003140;
    font-style: bold;
    font-family: 'Lato', Helvetica, sans-serif;
    margin: auto 3px auto auto;
}

.article__created_date {
    color: #777;
    font-family: 'Lato', Helvetica, sans-serif;
    margin: auto 3px;
}

.article__weekday {
    font-weight: bold;
    margin: auto 3px;
}

.article__content {
    color: black;
    font-family: 'Lato', Helvetica, sans-serif;
    margin: 10px auto;
}

.article__content a {
    color: #079 ;
    font-style: none;
}

/* ArticleTitle */
h1.article__title {
    color: #222;
    font-size: 40px;
    margin: 10px auto;
}

