.editor-article-listing__header {
    display: inline-block;
    font-family: 'Oswald', sans-serif;
    margin: 0px;
    margin-right: 13px;
}

.editor-article-listing__user-header {
    display: inline-block;
    color: #555;
    margin: 0px;
}

.editor-article-listing__new-article-button {
    float: right;
    display: inline-block;

    width: 100px;
    height: 40px;

    padding: 3px;
}

.editor-article-listing__topbox {
    display: inline-block;
    margin: 0px 7px;
    padding: 3px;
    vertical-align: bottom;
}

.editor-article-listing__new-article-popup-container {
    position: relative;
}

/* New Article popup */
.editor-article-listing__new-article-popup {}

.editor-article-listing__new-article-popup__button {
    margin: 5px;
}

/* Switch Modes button & popup */
.edit-article-panel__switch-modes-button {

}

.edit-article-panel__switch-modes-confirmation-popup {
    display: none;
}