.editor-loginform {

}

.editor-loginform__title {
    margin: 10px 5px;
}

.editor-loginform__field {
    margin: 0px 10px 10px 10px;
}

.editor-loginform__fieldtitle {
    margin: 5px 0px;
}

.editor-loginform__textinput {
    padding: 3px 8px;
    height: 35px;
    width: 220px;
    font-size: 11pt;
    border-color: black;
    border-width: 1px;
    margin: 5px 0px;
    border-radius: 2px;
}

.editor-loginform__submitbutton {
    border-radius: 0px;
    padding: 10px;
    background: white;
    border-style: solid;
    border-color: black;
    border-radius: 2px;
}


/* error fields */