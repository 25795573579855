.edit-article-panel {

}

.edit-article-panel__header {
    font-family: 'Oswald', Helvetica, Arial, sans-serif;
}


/* Generics */
.edit-article-panel__input {
    font-family: 'Lato', sans-serif;
    outline-width: 0;
    width: 100%;
    display: block;
    height: 32px;
    padding: 2px 5px;
    margin: 5px 0px;
    background-color: white;
    font-size: 15px;
    border: black solid 1px;
    border-radius: 1px;
}

.edit-article-panel__textarea {
    font-family: 'Lato', sans-serif;
    outline-width: 0;
    resize: none;
    width: 100%;
    height: 175px;
}

.edit-article-panel__field-error {
    font-family: 'Lato', sans-serif;
    color: red;
    font-size: 12px;
    margin: 0px;
}

.edit-article-panel__submit-button {
    width: 100px;
    height: 40px;
    margin: 10px 0px;
}


/* EditArticlePreview */
.edit-article-panel__preview {
    max-width: 900px;
    margin: auto;
}

.edit-article-panel__preview-header {
    font-family: 'Lato', sans-serif;
}

.edit-article-panel__preview-content {
    font-family: 'Lato', sans-serif;
    position: static;
    margin: 5px auto;
    border-width: 1px;
    border-color: #555;
    border-style: solid;
    width: 100%;
    height: 30em;
    overflow: auto;
    padding: 0px 5px;
}

.edit-article-panel__preview-content::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}