.user-page__user-website-link {
    color: #44a;
}

.user-page__user-website-box {
    background: white;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    border-color: black;
    display: inline-block;
    padding: 4px 8px;
    margin: 9px 0px 0px 0px;
}

.user-page__user-website-line {
    margin: 4px 3px;
}

.user-page__user-website-line_h1 {
    margin-right: 3px;
    border-right: 1px solid black;
    height: 100%;
}

/* User Info Card */
/* { maxWidth: 600 } */
.user_page__user-info-card {
    text-align: center;
    margin: auto;
    width: 95%;
    max-width: 300px;
}

.user-info-card__user-info-box__display-name {
    top: 5px;
}

.user-info-card__photo-box {
    width: 150px;
    height: 150px;
    display: inline-block;
}
.user-info-card__photo-box__photo {
    width: inherit;
    height: inherit;
    box-shadow: 1px 1px 15px;
}

.user-info-card__info-box {
    display: inline-block;
    margin: 8px;
    vertical-align: top;
}


/* User Page - Error Popup */
.user-page__error-popup__header {
    color: #c11;
}

.user-page__error-popup__text {
    font-weight: bold;
    font-size: 20px;
}