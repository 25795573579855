/* Body */

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0px;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

a:hover {
    text-decoration: none;
}

a {
    text-decoration: none;
}

body {
    font-family: sans-serif;
}

p {
    font-size: 16px;
}

code {
    font-size: 15px;
    color: #111;
    font-family: 'PT Mono', monospace;

}

button {
    font-family: 'Bebas Neue', 'Lato', Helvetica, sans-serif;
    background-color: #20c9bb;
    color: black;
    font-size: 20px;
    
    border-width: 1px;
    border-color: #777;
    border-radius: 2px;
    cursor: pointer;

    box-shadow: 1px 1px black;
    outline: none;
}

button:hover {
    filter: brightness(95%);
}

button:active {
    box-shadow: -1px -1px;
}
