
/* Small */
@media (max-width: 992px) { 
    .home-page {
        margin-left: 15px;
        margin-right: 15px;
    }
}

/* Larger */
@media (min-width: 992px) { 
    .home-page {
        margin-left: 10%;
        margin-right: 10%;
    }
}

.home-page__contact-footer {
    text-align: center;
    vertical-align: center;
}

.home-page__contact-line {
    display: inline-block;
    background-color: white;

    border-style: solid;
    border-color: #222;
    border-radius: 5px;
    border-width: 1px;

    padding: 9px 12px;
}

.home-page__contact-link {
    margin-left: 7px;
}