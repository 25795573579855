/* Nav */

.default-navbar {
    background-color: #20c9bb;
    box-shadow: 0px 1px 2px #032;

    /* fixed top */
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;

    padding: 5px 10px;
}