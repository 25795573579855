/* Article Listing */

.article-list-item__title {
    color: #222;
    font-size: 30px;
}

.article-list-item__author {
    font-weight: bold;
    color: #222;
    margin-right: 8px;
}

.article-list-item__sub {
    color: #888;
}

.article-list-item__description {

}

.article-list-item__date {

}