.app {
  
}

.app__background { 
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: "100%";
  z-index: -99;
}