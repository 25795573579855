/* Editor Article List Item */
.editor-article-list-item {
    position: relative;
}

.editor-article-list-item__title {
    color: #222;
    font-size: 30px;
}

.editor-article-list-item__author {
    font-weight: bold;
    color: #222;
    margin-right: 8px;
}

.editor-article-list-item__sub {
    color: #888;
}

.editor-article-list-item__description {

}

.editor-article-list-item__date {
    min-width: 190px;
    display: inline-block;
}

.editor-article-list-item__delete-button {
    background-color: #c11;
    color: white;
    margin-top: 6px;
}

/* Article List Item - Delete Popup */
.editor-article-list-item_delete-popup-container {
    margin: 0px;
    position: absolute;
    top: 0px;
    left: 0px;
}

.editor-article-list-item_delete-popup {

}

.editor-article-list-item_delete-popup__button {
    margin: 5px;
}